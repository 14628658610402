<template>
  <!-- <b-card-code> -->
  <div id="app">
    <div class="d-flex justify-content-between">
      <b-button style="margin-top: -15px;" @click="scroll_left" variant="flat-dark" class="btn-icon mr-3">
        <feather-icon style="width: 25px;height: 25px;" icon="ChevronsLeftIcon" />
      </b-button>
      <b-row id="exa" class="example" style="overflow: scroll;">
        <b-tabs align="left" pills>
          <div v-for="value in tabs">
            <b-tab v-if="name == value.name" @click="clickTab(value)" :key="key" active>
              <template #title>
                <feather-icon width="16" height="16" :icon="value.icon" />
                <span style="white-space: nowrap;font-size: 14x;">{{ value.name }}</span>
              </template>
            </b-tab>
            <b-tab v-if="name !== value.name" @click="clickTab(value)" :key="key">
              <template #title>
                <feather-icon width="14" height="14" :icon="value.icon" />
                <span style="white-space: nowrap;font-size: 13px;">{{ value.name }}</span>
              </template>
            </b-tab>
          </div>
        </b-tabs>
      </b-row>
      <b-button style="margin-top: -15px;" @click="scroll_right" variant="flat-dark" class="btn-icon ml-3">
        <feather-icon style="width: 25px;height: 25px;" icon="ChevronsRightIcon" />
      </b-button>
    </div>
  </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import axios from '@/components/axios'
import { BTabs, BTab, BRow, BButton } from 'bootstrap-vue'


export default {
  components: {
    BCardCode,
    BTabs,
    BTab,
    BRow,
    BButton
  },
  props: ['tabs', 'name'],
  data () {
    return {
      TabValue: '',
      scrollAmount: 0
    }
  },
  mounted () {
    this.setMethod()
    // this.rights()
  },
  methods: {
    async rights () {
      const userId = JSON.parse(localStorage.getItem('userData'))
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const data = {
        id: userId.id,
        type: 'User'
      }
      const requestOptionsUsers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        url: `${baseApi}/getSidebardata`
      }

      await axios(requestOptionsUsers)
        .then((response) => {
          this.rightsdata = response.data.data
          let arr = []
          Object.keys(this.rightsdata).forEach((key) => {
            arr = [...arr, { ...this.rightsdata[key] }]
          })
          this.tab = this.tabs
          this.tabs = []
          this.rightsdata.map((data) => {
            data.children.map((children) => {
              if (children.children) {
                children.children.map((children1) => {
                  if (this.tab[0].name == 'Lead Source') {
                    if (children1.route.includes('leadsource')) {
                      this.tabs[0] = this.tab[0]
                    }
                    if (children1.route.includes('leadstage')) {
                      this.tabs[1] = this.tab[1]
                    }
                    if (children1.route.includes('campaign')) {
                      this.tabs[2] = this.tab[2]
                    }
                    if (children1.route.includes('reason')) {
                      this.tabs[3] = this.tab[3]
                    }
                    if (children1.route.includes('ChannelPartner')) {
                      this.tabs[4] = this.tab[4]
                    }
                    if (children1.route.includes('franchise')) {
                      this.tabs[5] = this.tab[5]
                    }
                    if (children1.route.includes('project')) {
                      this.tabs[6] = this.tab[6]
                    }
                    if (children1.route.includes('plot')) {
                      this.tabs[7] = this.tab[7]
                    }
                    if (children1.route.includes('land')) {
                      this.tabs[8] = this.tab[8]
                    }
                    if (children1.route.includes('crm-commission')) {
                      this.tabs[9] = this.tab[9]
                    }
                    if (children1.route.includes('projectmapping')) {
                      this.tabs[10] = this.tab[10]
                    }
                    this.$forceUpdate()
                  } else if (this.tab[0].name == 'Holiday') {
                    if (children1.route.includes('holiday')) {
                      this.tabs[0] = this.tab[0]
                    }
                    if (children1.route.includes('holidayname')) {
                      this.tabs[1] = this.tab[1]
                    }
                    if (children1.route.includes('region')) {
                      this.tabs[2] = this.tab[2]
                    }
                    if (children1.route.includes('applypost')) {
                      this.tabs[3] = this.tab[3]
                    }
                    if (children1.route.includes('asset')) {
                      this.tabs[4] = this.tab[4]
                    }
                    if (children1.route.includes('cast')) {
                      this.tabs[5] = this.tab[5]
                    }
                    if (children1.route.includes('document')) {
                      this.tabs[6] = this.tab[6]
                    }
                    this.$forceUpdate()
                  } else if (this.tab[0].name == 'Ledger') {
                    if (children1.route.includes('ledger')) {
                      this.tabs[0] = this.tab[0]
                    }
                    if (children1.route.includes('accountgroup')) {
                      this.tabs[1] = this.tab[1]
                    }
                    if (children1.route.includes('bankaccount')) {
                      this.tabs[2] = this.tab[2]
                    }
                    if (children1.route.includes('bankstatement')) {
                      this.tabs[3] = this.tab[3]
                    }
                    if (children1.route.includes('vendor')) {
                      this.tabs[4] = this.tab[4]
                    }
                    if (children1.route.includes('vendortype')) {
                      this.tabs[5] = this.tab[5]
                    }
                    if (children1.route.includes('vendorcategory')) {
                      this.tabs[6] = this.tab[6]
                    }
                    if (children1.route.includes('broker')) {
                      this.tabs[7] = this.tab[7]
                    }
                    if (children1.route.includes('farmer')) {
                      this.tabs[8] = this.tab[8]
                    }
                    if (children1.route.includes('purchasebuyer')) {
                      this.tabs[9] = this.tab[9]
                    }
                    this.$forceUpdate()
                  }
                  // })
                })
              }
            })
          })
        })
    },
    setMethod () {
      // this.tabs.map((item ) => {
      //   console.log(item,"tab--")
      // })
      // console.log(this.TabValue, 'tab----')
      // console.log(this.name, 'tab----')
    },
    clickTab (value) {
      this.$router.push(value.route)
    },
    scroll_left () {
      const content = document.getElementById('exa')
      content.scrollLeft -= 100
    },
    scroll_right () {
      const content = document.getElementById('exa')
      content.scrollLeft += 100
    }
  }
}
</script>
<style>
.nav {
  flex-flow: nowrap !important;
}

.example::-webkit-scrollbar {
  display: none !important;
}

.nav-wrapper {
  position: relative;
  /* max-width: 310px; */
  height: 100px;
  margin: 1em auto;
  border: 1px solid black;
  overflow-x: hidden;
  overflow-y: hidden;
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  /* width: 3em; */
}

.left-paddle {
  left: 0;
}

.right-paddle {
  right: 0;
}
</style>
